import { Link } from "gatsby";
import { Counter, LikeButton, RegVideo2, Video3 } from "..\\..\\..\\..\\src\\components\\Complete";
import * as React from 'react';
export default {
  Link,
  Counter,
  LikeButton,
  RegVideo2,
  Video3,
  React
};