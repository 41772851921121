import { Link } from "gatsby";
import { Counter, LikeButton, RegVideo, Video1 } from "..\\..\\..\\..\\src\\components\\Complete";
import * as React from 'react';
export default {
  Link,
  Counter,
  LikeButton,
  RegVideo,
  Video1,
  React
};